import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input, Typography, Spin, Row, Col, Modal } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { loginUser } from '../redux/userRelated/userHandle';
import { LightPurpleButton } from '../components/buttonStyles'; // Adjust this for Ant Design
import bgpic from "../assets/loginbg.jpg";
import styled from 'styled-components';

const { Title, Text } = Typography;

const LoginPage = ({ role }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { status, currentUser, response, error, currentRole } = useSelector(state => state.user);

    const [toggle, setToggle] = useState(false);
    const [guestLoader, setGuestLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState("");

    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        if (role === "Student") {
            const { rollNumber, studentName, password } = values;

            if (!rollNumber || !studentName || !password) {
                return;
            }
            const fields = { rollNum: rollNumber, studentName, password };
            setLoader(true);
            dispatch(loginUser(fields, role));
        } else {
            const { email, password } = values;

            if (!email || !password) {
                return;
            }

            const fields = { email, password };
            setLoader(true);
            dispatch(loginUser(fields, role));
        }
    };

    const guestModeHandler = () => {
        const password = "zxc";

        if (role === "Admin") {
            const email = "yogendra@12";
            const fields = { email, password };
            setGuestLoader(true);
            dispatch(loginUser(fields, role));
        } else if (role === "Student") {
            const rollNum = "1";
            const studentName = "Janak Raj Ojha";
            const fields = { rollNum, studentName, password };
            setGuestLoader(true);
            dispatch(loginUser(fields, role));
        } else if (role === "Teacher") {
            const email = "tony@12";
            const fields = { email, password };
            setGuestLoader(true);
            dispatch(loginUser(fields, role));
        }
    };

    useEffect(() => {
        if (status === 'success' || currentUser !== null) {
            if (currentRole === 'Admin') {
                navigate('/Admin/dashboard');
            } else if (currentRole === 'Student') {
                navigate('/Student/dashboard');
            } else if (currentRole === 'Teacher') {
                navigate('/Teacher/dashboard');
            }
        } else if (status === 'failed') {
            setMessage(response);
            setShowPopup(true);
            setLoader(false);
        } else if (status === 'error') {
            setMessage("Network Error");
            setShowPopup(true);
            setLoader(false);
            setGuestLoader(false);
        }
    }, [status, currentRole, navigate, error, response, currentUser]);

    return (
        <div style={{ height: '100vh', backgroundImage: `url(${bgpic})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Row justify="center" align="middle" style={{ height: '100%' }}>
                <Col xs={24} sm={16} md={12} lg={8}>
                    <div style={{ padding: '24px', background: '#fff', borderRadius: '8px' }}>
                        <Title level={4} style={{ marginBottom: '16px', color: "#2c2143" }}>
                            {role} Login
                        </Title>
                        <Text>Welcome back! Please enter your details</Text>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            style={{ marginTop: '24px' }}
                        >
                            {role === "Student" ? (
                                <>
                                    <Form.Item
                                        name="rollNumber"
                                        label="Enter your Roll Number"
                                        rules={[{ required: true, message: 'Roll Number is required' }]}
                                    >
                                        <Input type="number" />
                                    </Form.Item>
                                    <Form.Item
                                        name="studentName"
                                        label="Enter your name"
                                        rules={[{ required: true, message: 'Name is required' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            ) : (
                                <Form.Item
                                    name="email"
                                    label="Enter your email"
                                    rules={[{ required: true, message: 'Email is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[{ required: true, message: 'Password is required' }]}
                            >
                                <Input.Password
                                    type={toggle ? 'text' : 'password'}
                                    iconRender={visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                                    onChange={() => setToggle(!toggle)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Checkbox defaultChecked>Remember me</Checkbox>
                                <StyledLink href="#">Forgot password?</StyledLink>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary" htmlType="submit" style={{ width: '100%', backgroundColor: '#1890ff', borderColor: '#1890ff' }} // Custom styles
                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#40a9ff'}
                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1890ff'}
                                    htmlType="submit"
                                    loading={loader}
                                >
                                    Login
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="default"
                                    style={{ width: '100%', borderColor: '#7f56da', color: '#7f56da' }}
                                    onClick={guestModeHandler}
                                    loading={guestLoader}
                                >
                                    Login as Guest
                                </Button>
                            </Form.Item>
                            {role === "Admin" &&
                                <Row>
                                    <Col>
                                        Don't have an account?
                                    </Col>
                                    <Col style={{ marginLeft: '8px' }}>
                                        <StyledLink to="/Adminregister">
                                            Sign up
                                        </StyledLink>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </div>
                </Col>
            </Row>
            <Modal
                title="Error"
                visible={showPopup}
                onOk={() => setShowPopup(false)}
                onCancel={() => setShowPopup(false)}
            >
                <p>{message}</p>
            </Modal>
        </div>
    );
};

export default LoginPage;

const StyledLink = styled(Link)`
  margin-top: 9px;
  text-decoration: none;
  color: #7f56da;
`;
