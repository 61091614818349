import React from 'react';
import {Header} from '../components/common/Header'
import {Footer} from '../components/common/Footer'
import {Home} from './Home'

const Homepage = () => {
    return (

       <>

            <Header />
            <Home />
            <Footer />


           
                              
                            {/* <StyledText>
                                Don't have an account?{' '}
                                <Link to="/Adminregister" style={{color:"#550080"}}>
                                    Sign up
                                </Link>
                            </StyledText> */}
                        
        </>
    );
};

export default Homepage;


