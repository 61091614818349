import React, { useState } from "react";
import { Container, Grid, Paper } from "@mui/material";
import { LineChart, SparkLineChart } from "@mui/x-charts";
import styled from "styled-components";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllSclasses } from "../../redux/sclassRelated/sclassHandle";
import { getAllStudents } from "../../redux/studentRelated/studentHandle";
import { getAllTeachers } from "../../redux/teacherRelated/teacherHandle";
import SeeNotice from "../../components/SeeNotice";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";



// Component to render the gauge pointer
function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill="red" />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}

const AdminHomePage = () => {
  const dispatch = useDispatch();
  const { studentsList } = useSelector((state) => state.student);
  const { sclassesList } = useSelector((state) => state.sclass);
  const { teachersList } = useSelector((state) => state.teacher);
  const { currentUser } = useSelector((state) => state.user);
  const adminID = currentUser._id;

  useEffect(() => {
    dispatch(getAllStudents(adminID));
    dispatch(getAllSclasses(adminID, "Sclass"));
    dispatch(getAllTeachers(adminID));
  }, [adminID, dispatch]);

  const numberOfStudents = studentsList?.length || 0;
  const numberOfClasses = sclassesList?.length || 0;
  const numberOfTeachers = teachersList?.length || 0;
  const feesCollection = 23000; // Example static value for fees collection

  const generateLineChartData = (numStudents) => {
    // Generate data with a simple pattern based on numberOfStudents
    const data = [];
    for (let i = 0; i < numStudents; i++) {
      const value = Math.sin(i / 2) * 10; // Wave pattern
      data.push(value);
    }
    return data;
  };

  const generateHeartbeatData = (numStudents) => {
    const data = [];
    for (let i = 0; i < numStudents; i++) {
      const value = Math.sin(i * 2) * 10 + (i % 2 === 0 ? 10 : -10);
      data.push(value);
    }
    return data;
  };

  const MAX_VALUE = 50000; // Define the maximum value for the gauge

  const calculatePercentage = (value) => {
    return (value / MAX_VALUE) * 100; // Calculate percentage
  };

  const settings = {
    valueFormatter: (v) => `${Math.round(v)}%`, // Simplified value formatter
    height: 100,
    showTooltip: true,
    showHighlight: true,
  };

  const data = generateLineChartData(numberOfStudents);
  const dataBar = generateLineChartData(numberOfTeachers);
  const heartbeatData = generateHeartbeatData(numberOfClasses);

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3} >
            <StyledPaper elevation={0}  sx={{
              borderRadius:'20px'
          }}>
              <ChartWrapper>
                <LineChart
                  xAxis={[{ data: data.map((_, i) => i) }]} // Use the index for x-axis
                  series={[
                    {
                      data: data,
                      // area: true,
                    },
                  ]}
                  width={500}
                  height={300}
                />
              </ChartWrapper>
              <Title>Total Students</Title>
              <Data start={0} end={numberOfStudents} duration={2.5} />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StyledPaper elevation={0} sx={{
              borderRadius:'20px'
          }}>
              <ChartWrapper>
                <SparkLineChart
                  data={heartbeatData}
                  plotType="line"
                  width={150}
                  height={50}
                  {...settings}
                  lineWidth={2} // Thinner line for sparkline
                  margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
                />
              </ChartWrapper>
              <Title>Total Classes</Title>
              <Data start={0} end={numberOfClasses} duration={2.5} />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StyledPaper elevation={0} sx={{
              borderRadius:'20px'
          }}>
              <ChartWrapper>
                <SparkLineChart
                  data={dataBar}
                  plotType="bar"
                  width={150}
                  height={50}
                  {...settings}
                  margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
                />
              </ChartWrapper>
              <Title>Total Teachers</Title>
              <Data start={0} end={numberOfTeachers} duration={2.5} />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <StyledPaper elevation={0} sx={{
              borderRadius:'20px'
          }}>
              <ChartWrapper>
                <GaugeContainer
                  width={200}
                  height={90}
                  startAngle={-110}
                  endAngle={110}
                  value={calculatePercentage(23000)} // Example static value for gauge
                >
                  <GaugeReferenceArc />
                  <GaugeValueArc />
                  <GaugePointer />
                </GaugeContainer>
              </ChartWrapper>
              <Title>Fees Collection</Title>
              <Data start={0} end={feesCollection} duration={2.5} prefix="$" />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <SeeNotice />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const StyledPaper = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 40px;
  border:1px solid #ccc;
`;

const ChartWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
`;

const Title = styled.p`
  font-size: 1.25rem;
  margin: 0;
`;

const Data = styled(CountUp)`
  font-size: calc(1.3rem + 0.6vw);
  color: green;
`;

export default AdminHomePage;
