import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { notification, Modal, Table, Button, Spin } from 'antd';
import 'antd/dist/reset.css';  // Ensure Ant Design styles are applied

const { confirm } = Modal;

const Addmission = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRowId, setLoadingRowId] = useState(null); // To track which row is being processed
  const { currentUser } = useSelector(state => state.user);
  const { sclassesList } = useSelector(state => state.sclass);

  // Create a mapping from sclass ID to name
  const sclassMap = sclassesList.reduce((map, sclass) => {
    map[sclass._id] = sclass.sclassName;
    return map;
  }, {});

  const fetchData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/Student/RequestList/${currentUser._id}`);
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentUser._id]);

  const handleApprove = async (row) => {
    setLoadingRowId(row._id); // Set the loading state for the current row
    try {
      const { data: success } = await axios.post(`${process.env.REACT_APP_BASE_URL}/Student/ApprovedRequest/${row._id}/${sclassMap[row.sclassName]}`, {
        name: row.name,
        rollNum: parseInt(row.rollNum),
        email: row.email,
        password: row.password,
        sclassName: row.sclassName,
        adminID: currentUser._id
      });
      fetchData();
      notification.success({
        message: 'Success',
        description: success.message,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || 'Something went wrong.',
      });
    } finally {
      setLoadingRowId(null); // Reset the loading state
    }
  };

  const handleDecline = (row) => {
    confirm({
      title: 'Are you sure you want to decline this request?',
      content: `Name: ${row.name}\nRoll Number: ${row.rollNum}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          const { data: success } = await axios.delete(`${process.env.REACT_APP_BASE_URL}/Student/DeclineRequest/${row._id}/${sclassMap[row.sclassName]}`);
          fetchData();
          notification.success({
            message: 'Success',
            description: success.message,
          });
        } catch (error) {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message || 'Something went wrong.',
          });
        }
      },
    });
  };

  const columns = [
    {
      title: 'Roll No',
      dataIndex: 'rollNum',
      key: 'rollNum',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Class',
      key: 'sclassName',
      render: (_, record) => (
        <span>{sclassMap[record.sclassName] || 'Unknown'}</span> // Use the mapping to display the class name
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div>
          <Button
            type="default"
            onClick={() => handleApprove(record)}
            style={{ marginRight: '8px' }}
            loading={loadingRowId === record._id} // Show spinner if this row is being processed
          >
            Approve
          </Button>
          <Button
            type="default"
            danger
            onClick={() => handleDecline(record)}
          >
            Decline
          </Button>
        </div>
      ),
    },
  ];

  if (loading) {
    return <div style={{ textAlign: 'center', marginTop: '20px' }}><Spin size="large" /></div>;
  }

  return (
    <Table
      columns={columns}
      dataSource={Array.isArray(data) ? data : []}
      rowKey="_id"
      pagination={false}
      style={{ margin: '20px' }}
    />
  );
};

export default Addmission;
