import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Typography, Card, notification, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSclasses } from '../redux/sclassRelated/sclassHandle';
import 'antd/dist/reset.css'; // Ensure Ant Design styles are applied

const { Title } = Typography;
const { Option } = Select;

const Register = () => {
    const [form] = Form.useForm();
    const [rollNumber, setRollNumber] = useState('');
    const [sclassName, setSclassName] = useState('');
    const [school, setSchool] = useState('');

    const dispatch = useDispatch();
    const { sclassesList } = useSelector((state) => state.sclass);

    useEffect(() => {
        dispatch(getAllSclasses(0, "Sclass"));
    }, [dispatch]);

    useEffect(() => {
        generateUniqueRollNumber();
    }, []);

    const generateUniqueRollNumber = async () => {
        let newRollNumber;
        let isUnique = false;

        const existingRollNumbers = await fetchExistingRollNumbers();

        while (!isUnique) {
            newRollNumber = Math.floor(100000 + Math.random() * 900000);

            if (!existingRollNumbers.includes(newRollNumber)) {
                isUnique = true;
                setRollNumber(newRollNumber);
                form.setFieldsValue({ rollNum: newRollNumber });
            }
        }
    };

    const fetchExistingRollNumbers = async () => {
        // Simulate fetching existing roll numbers from the server
        return [123456, 234567, 345678];
    };

    const handleClassChange = (value) => {
        const selectedClass = sclassesList.find(
            (classItem) => classItem.sclassName === value
        );
        setSclassName(selectedClass ? selectedClass._id : '');
        setSchool(selectedClass ? selectedClass.school : '');
    };

    const handleSubmit = async (values) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Student/Request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...values,
                    rollNum: rollNumber,
                    sclassName,
                    school,
                }),
            });

            if (response.ok) {
                notification.success({
                    message: 'Registration Successful',
                    description: 'You have been registered successfully.',
                });
                form.resetFields();
                generateUniqueRollNumber();
            } else {
                notification.error({
                    message: 'Registration Failed',
                    description: 'Registration failed. Please try again.',
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred. Please try again.',
            });
        }
    };

    return (
        <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card
                title={<Title level={3}>Register</Title>}
                style={{ width: 500 }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{ rollNum: rollNumber }}
                >
                    <Form.Item
                        label="Roll Number"
                        name="rollNum"
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Select Course"
                        name="sclassName"
                        rules={[{ required: true, message: 'Please select a course!' }]}
                    >
                        <Select onChange={handleClassChange}>
                            {sclassesList.map((classItem) => (
                                <Option key={classItem._id} value={classItem.sclassName}>
                                    {classItem.sclassName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#1890ff', borderColor: '#1890ff' }} // Custom styles
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#40a9ff'}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1890ff'}>
                            Register
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default Register;
